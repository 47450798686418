<template>
  <div>
    <h1 class="page-title whitespace-wrapper">{{ qre.name }}</h1>
    <small>{{ $t("singleQREUserView.created") }} {{ qre.whenCreated }}</small>
    <br /><br />
    <p>
      <b>{{ $t("singleQREUserView.status") }}</b>
      <b-icon icon="circle-fill" v-bind:color="qre.status"></b-icon>
    </p>
    <p>
      <b>{{ $t("singleQREUserView.coresAmount") }}</b> {{ qre.cores }}
    </p>
    <p>
      <b>{{ $t("singleQREUserView.ramAmount") }}</b> {{ qre.ram }}
      {{ $t("singleQREUserView.gb") }}
    </p>
    <p>
      <b>{{ $t("singleQREUserView.diskSpace") }}</b> {{ qre.hdd }}
      {{ $t("singleQREUserView.gb") }}
    </p>
    <br />
    <h4>{{ $t("singleQREUserView.accessQreData") }}</h4>
    <p>{{ accessData }}</p>
  </div>
</template>

<script>
export default {
  name: "SingleQRE",
  data() {
    return {
      qre: {
        name: "Quantum Runtime Environment 01",
        whenCreated: "1st of January, 2021",
        cores: 20,
        ram: 100,
        hdd: 1000,
        status: "green",
      },
      accessData: "...",
    };
  },
};
</script>

<style></style>
